.container {
  background: white;
  display: grid;
  box-shadow: 0px 0px 4px 2px #2E2E2E05;
  border: 1px solid #DADFE6;
  padding: 16px;
  position: relative;
}

.container a {
  color: var(--secondary-contrast-text);
}

.thumbnail :global .image {
  object-fit: cover;
}

.title {
  font-weight: 600;
  line-height: 22px;
  color: var(--secondary-contrast-text-3);
  text-align: left;
  margin: 0;
  grid-column: span 2;
}

.subtitle {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: var(--secondary-contrast-text-2);

}

.specContainer {
  display: flex;
  flex-direction: row;
  gap: 4px;
  overflow-x: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  white-space: nowrap;
}

.spec {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  display: grid;
  grid-template: auto / auto 1fr;
  gap: 3px;
  flex-shrink: 0; /* Prevent items from shrinking */
}

.dot {
  color: var(--secondary-contrast-text-2);
  font-weight: 200;
}

.spec:last-child .dot {
  display: none;
}

.dates {
  font-weight: bold;
  color: var(--secondary-contrast-text);
}

.pricingContainer {
  display: grid;
}

.excTax {
  color: #a2a2a2;
  text-transform: uppercase;
}

.strike {
  text-decoration: line-through;
  margin-left: 4px;
}

.hideCollection {
  grid-row: 5;
  grid-column: 1/span 2;
}

.hideDivider {
  display: none;
}

.view {
  border-radius: 4px;
  padding: 15px 12px;
  border: 1px solid #1F2C38;
  text-transform: capitalize;
  color: #1F2C38;
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
}

.badge {
  position: absolute;
}

.partnerHome {
  position: absolute;
  display: grid;
  gap: 8px;
  padding: 6px 10px;
  border-radius: 35px;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(27.1828px);
  color: white;
  align-items: center;
  font-size: 11px;
}

.partnerHomeIcon {
  background: white;
  padding: 8px;
  border-radius: 50%;
}

.soldOut {
  border-radius: 4px;
  font-weight: bold;
  color: #da6528;
  text-transform: capitalize;
  /* position: absolute; */
  float: right;
}

.upcoming {
  border-radius: 4px;
  background-color: rgba(250, 100, 0, 0.1);
  font-weight: 500;
  color: #cc580b;
  text-transform: uppercase;
  position: absolute;
  font-size: 10px;
  padding: 8px 14px;
}

.unavailable {
  border-radius: 4px;
  background-color: rgba(183, 55, 53, 0.08);
  font-weight: 500;
  color: #b73735;
  text-transform: capitalize;
  position: absolute;
}

.black {
  background: #24303c;
  box-shadow: 0 1px 24px 1px rgba(0, 0, 0, 0.05);
  border: 1px solid #3a5157;
}

a.black {
  color: white;
}

.black .title {
  color: white;
}

.black .subtitle {
  color: white;
}

.black .spec {
  border-color: #3a5157;
}

.black .collection {
  color: white;
}

.black .excTax {
  color: white;
}

.black .amount {
  color: white;
}

.black .strike {
  color: #c7a68e;
}

.black .view {
  color: #c7a68e;
}

.black .horizontalDivider {
  border-right: 1px solid white;
}

.black .collectionContainer {
  border-top: 1px solid white;
}

.black .view {
  border: 1px solid white;
}

.black .collectionIcon {
  background: white;
  border-radius: 50%;
}

.VillaTagContainer {
  background: #FFFFFF;
  border-radius: 4px;
  padding: 7px 4px;
  position: absolute;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  color: var(--secondary-contrast-text);
  align-items: center;
}

.wellness {
  position: absolute;
  height: 32px;
}

.llpTicker {
  margin-right: 8px;
}

.imgSelect {
  object-fit: cover;
  width: 100%;
  height: 100%;
  object-position: center;
  cursor: default;
}

.divider {
  border-bottom: 1px solid #E6E6E699;
  /* grid-row: 4;
  align-self: start;
  grid-column: 2 / span 3; */
}

.horizontalDivider {
  grid-row: 1 / span 6;
  grid-column: 5;
  border-right: 1px solid #0000001A;
}

.amount {
  color: var(--secondary-contrast-text-3);
}

.totalAmount {
  color: var(--secondary-contrast-text);
}

@media (max-width: 768px) {
  .container {
    padding: 12px;
    grid-template: auto/1fr;
    gap: 12px;
    border-radius: 4px;
  }

  .thumbnail {
    width: calc(100% + 24px);
    height: 188px;
    margin: -12px -12px 0 -12px;
    border-radius: 4px 4px 0 0;
    grid-column: span 3;
    scroll-snap-type: x mandatory;
  }

  .thumbnailContainer {
    border-radius: 4px 4px 0 0;
    grid-column: 1 / span 3;
    display: grid;
    grid-template: auto / 1fr;
    position: relative;
    grid-row: 1;
  }

  .priceContainer {
    grid-row: span 2;
    align-content: center;
  }

  /* .divider{
    grid-row: 5;
    grid-column: 1 / span 3;
  } */

  .item {
    scroll-snap-align: start;
    overflow: hidden;
    width: calc(100vw - 32px);
    background-color: black;
  }

  .scrollCrumb {
    grid-column: 1;
    grid-row: 1;
    align-self: flex-end;
    justify-self: center;
    margin-bottom: -170px;
  }

  .title {
    grid-column: 1 / span 2;
    font-size: 16px;
    line-height: 16px;
    grid-row: 2;
    overflow: auto;
  }

  .VillaTagContainer {
    top: 12px;
    left: 12px;
    padding: 6px 4px;
  }

  .ratingCounts {
    border-radius: 4px;
    grid-column: 3;
    grid-row: 2 / span 2;
    max-height: 28px;
    background: #EBB4221A;
    padding: 4px;
    width: fit-content;
    justify-self: end;
  }

  .subtitle {
    font-size: 12px;
    line-height: 16px;
    margin-top: -8px;
    grid-column: 1 / span 2;
    grid-row: 3;
  }

  .specContainer {
    grid-column: 1 / span 3;
    grid-row: 4;
    gap: 6px;
    margin-top: 4px;
    padding-bottom: 4px; /* Add some padding to show scroll hint */
  }

  .spec {
    font-size: 12px;
    gap: 6px;
  }

  .horizontalDivider {
    display: none;
  }

  .datesContainer {
    line-height: 16px;
    font-size: 10px;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    color: var(--secondary-contrast-text);
  }

  .strike {
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    text-align: left;
    align-content: center;
    grid-column: 1;
    grid-row: 1;
    margin-left: 0;
  }

  .dates {
    font-size: 12px;
    font-weight: 600;
    line-height: 14px;
    text-align: center;
  }

  .pricingContainer {
    font-size: 10px;
    border-top: 1px solid #E6E6E699;
    padding-top: 12px;
    grid-column: 1/span 3;
    height: fit-content;
    grid-row: 6;
    grid-template: auto / auto auto auto;
    column-gap: 8px;
  }

  .collectionContainer {
    grid-column: 1/ span 3;
    padding-top: 12px;
    grid-row: 5;
    display: flex;
    gap: 16px;
  }

  .collectionContent {
    display: grid;
    grid-template: auto / 1fr auto;
    gap: 4px;
    align-items: center;
  }

  .collection {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
    color: var(--secondary-contrast-text);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .collectionIcon {
    width: 24px;
    height: 24px;
  }

  .view span {
    display: none;
  }

  .view {
    align-self: center;
    border: 1px solid #DADFE6;
    border-radius: 50%;
    padding: 10px;
    height: fit-content;
  }

  .price {
    margin-top: 6px;
    /* grid-column: 1; */
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    width: fit-content;
  }

  .benefitsIcon {
    width: 12px;
    height: 12px;
  }

  .additionalBenefitsContainer {
    background: linear-gradient(90deg, rgba(151, 78, 38, 0.05) 0%, rgba(151, 78, 38, 0.03) 100%);
    margin: 0px -12px -12px -12px;
    border-radius: 0 0 4px 4px;
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 4px;
    justify-content: center;
    grid-column: 1 / span 3;
  }

  .additionalBenefits {
    color: #974E26B2;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
  }

  .benefitsContainer {
    display: flex;
    gap: 2px;
  }

  .benefitsContent {
    font-size: 12px;
    font-weight: 500;
    line-height: 12px;
    color: #974E26;
  }

  /* .price[data-sold-out='true'] {
    grid-column: 2;
    grid-row: 1;
    display: flex;
    align-items: center;
    margin-left: 4px;
  } */

  .excTax {
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    margin-top: 2px;
    font-weight: 400;
    text-transform: capitalize;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .llpTicker {
    grid-row: 1;
    grid-column: 2;
    margin-right: 0;
  }

  .view {
    grid-column: 3;
    grid-row: span 2;
    justify-self: end;
  }

  .bedroomCount {
    border: 1px solid #C0C5CE;
    background: #F9F9F9;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
    color: var(--secondary-contrast-text-2);
    padding: 11px;
    border-radius: 4px;
    text-align: center;
    height: fit-content;
    align-self: center;
    grid-row: 1 / span 2;
    grid-column: 1;
  }

  /* .view[data-sold-out='true'] span{
    display: none;
  } */

  .amount {
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    text-align: left;
    margin-right: 2px;
  }

  .totalAmount {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    text-align: left;
    margin-top: 8px;
  }

  .startFrom {
    display: grid !important;
    gap: 4px;
    justify-content: flex-start !important;
  }

  .badge {
    width: 52px;
    top: 36px;
    right: 8px;
    transform: translateY(-48%);
  }

  .partnerHome {
    top: 12px;
    right: 14px;
    grid-template: auto/1fr auto;
  }

  .partnerHomeIcon {
    grid-column: 2;
    grid-row: 1;
    margin: -20px -18px -20px 0;
  }

  .priceHeight{
    margin-top: -12px;
  }

  .soldOutContainer {
    border: 1px solid #FFECE3;
    border-radius: 4px;
    grid-column: 1 / span 3;
    grid-row: 7;
    display: flex;
    align-self: self-end;
    justify-content: center;
    min-height: 36px;
    background-color: #F8E0D499;
    border: 1px solid #F4D1BF;
    gap: 2px;
    margin-top: 4px;
  }

  .soloSoldOut {
    grid-column: 1 / span 3;
    grid-row: 7;
    min-height: 36px;
    border: 1px solid #FFECE3;
    background: #FFECE3CC;
    display: flex;
    border-radius: 4px;
    justify-content: center;
    margin-top: 4px;
  }

  .dot {
    color: #DDDDDD;
    font-weight: 500;
  }

}

.soldOut {
  font-size: 12px;
  font-weight: 600;
  line-height: 12px;
  text-align: left;
  color: #DA6528;
  align-self: center;
  text-transform: capitalize;
}

.upcoming {
  right: 48px;
  bottom: 16px;
}

.unavailable {
  font-size: 10px;
  padding: 8px 14px;
  right: 16px;
  bottom: 16px;
}

.startFrom {
  display: flex;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--secondary-contrast-text-2);
  justify-content: flex-end;
}

.wellness {
  top: 165px;
  right: 16px;
}

.collectionContainer {
  border-top: 1px solid #E6E6E699;
}

@media (min-width: 768px) {
  .container {
    grid-template: repeat(3, auto) 1fr auto/auto auto 1fr;
    gap: 20px 16px;
    border-radius: 6px;
  }

  .thumbnailContainer {
    margin: -16px 0 -16px -16px;
    grid-column: 1;
    grid-row: 1 / span 6;
    display: grid;
    grid-template: auto/1fr;
    position: relative;
    /* min-width: 300px; */
  }

  .leftContainer {
    margin-left: -16px;
  }

  .thumbnail {
    max-height: 280px;
    min-height: 280px;
    max-width: 350px;
    width: calc(100vw - 1100px);
    border-radius: 4px 0px 0px 4px;
    scroll-snap-type: x mandatory;
    grid-row: span 1;
    grid-column: span 3;
    min-width: 300px;
  }

  .item {
    scroll-snap-align: start;
    overflow: hidden;
    width: calc(100vw - 1100px);
    max-width: 350px;
    background-color: black;
    min-width: 300px;
  }

  .scrollCrumb {
    grid-column: 1;
    grid-row: 2;
    margin-top: -32px;
    align-self: flex-end;
    justify-self: center;
  }

  .scrollCrumb .rightArrowIcon {
    right: 16px;
  }

  .scrollCrumb .leftArrowIcon {
    left: 16px;
  }

  .title {
    font-size: 20px;
    padding-right: 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .collectionContainer {
    display: flex;
    gap: 16px;
    grid-row: 4;
    grid-column: 2 / span 3;
    height: fit-content;
    padding-top: 16px;
  }

  .collectionIcon {
    width: 24px;
    height: 24px;
  }

  .collectionContent {
    display: grid;
    grid-template: auto / 1fr auto;
    gap: 6px;
    align-items: center;
  }

  .collection {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
    color: var(--secondary-contrast-text);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .additionalBenefitsContainer {
    display: grid;
    gap: 4px;
    grid-column: 6;
    grid-row: 1;
  }

  .additionalBenefits {
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    text-align: right;
    color: #974E26B2;
  }

  .benefitsContainer {
    display: flex;
    gap: 4px;
    justify-content: flex-end;
  }

  .benefitsIcon {
    width: 14px;
    height: 14px;
  }

  .benefitsContent {
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    text-align: left;
    color: var(--secondary-contrast-text);
  }

  .subtitle {
    font-size: 14px;
    margin-top: -18px;
    grid-row: 2;
    grid-column: 2 / span 2;
  }

  /* .finalPriceContainer{
    display: flex;
    align-items: center;
  } */

  .ratingCounts {
    background: #EBB4221A;
    grid-row: 6;
    grid-column: 2;
    border-radius: 4px;
    border: none;
    padding: 2px 4px;
    width: fit-content;
    height: fit-content;
    align-self: center;
  }

  .bedroomCount {
    border: 1px solid #C0C5CE;
    background: #F9F9F9;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: var(--secondary-contrast-text-2);
    padding: 11px;
    border-radius: 4px;
    width: fit-content;
    justify-self: end;
    margin-bottom: 4px;
  }

  .specContainer {
    grid-column: 2 / span 3;
    grid-row: 3;
    margin-top: -6px;
    padding-bottom: 4px; /* Add some padding to show scroll hint */
  }

  .datesContainer {
    font-weight: 400;
    font-size: 12px;
    font-size: 10px;
    line-height: 16px;
    border: 1px solid #FFECE3;
    border-radius: 4px;
    padding: 8px 6px;
    text-align: left;
    margin-left: -4px;
    color: var(--secondary-contrast-text-2);
    background: #FDF7F4;
    width: max-content;
  }

  .soldOutContainer .soldOut {
    padding: 8px 4px !important;
    border-radius: 4px 4px 0 0;
    width: 43px;
    height: 100%;
    border-radius: 4px 0px 0px 4px;
    font-size: 10px;
    font-weight: 600;
    line-height: 12px;
    text-align: center;
    color: #DA6528;
    align-content: center;
  }

  .dates {
    font-size: 12px;
    font-weight: 600;
    line-height: 14px;
    text-align: left;
    color: var(--secondary-contrast-text);
  }

  .pricingContainer {
    gap: 4px;
    font-size: 12px;
    align-self: flex-end;
    justify-self: flex-end;
    grid-column: 6;
    grid-row: 3 / span 4;
    grid-template: auto/1fr;
  }

  .startFrom {
    text-align: right;
    margin-bottom: 4px;
  }

  .price {
    text-align: right;
    font-size: 20px;
    font-weight: 600;
    line-height: 19px;
    text-align: right;
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .excTax {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    text-align: right;
    color: var(--secondary-contrast-text-2);
    text-transform: capitalize;
  }

  .amount {
    font-weight: 600;
    font-size: 20px;
    line-height: 19px;
    letter-spacing: 0%;
    text-align: right;
  }

  .totalAmount {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    text-align: right;
    margin-top: 8px;
  }

  .view {
    justify-self: flex-end;
    margin-top: 18px;
    max-height: 40px;
  }

  .badge {
    width: 42px;
    left: 24px;
    bottom: 28px;
  }

  .partnerHome {
    left: 28px;
    bottom: 30px;
    grid-template: auto/auto 1fr;
  }

  .partnerHomeIcon {
    margin: -20px 0 -20px -18px;
  }

  .soldOut {
    border-radius: 4px;
    text-transform: capitalize;
    color: #DA6528;
    font-size: 12px;
    font-weight: 600;
    line-height: 14px;
    padding: 6px 10px;
    text-align: center;
    background: #FFECE3;
  }

  .soldOutContainer {
    grid-column: 3;
    grid-row: 6;
    width: fit-content;
    display: grid;
    grid-template: auto / 1fr auto;
  }

  .soloSoldOut {
    grid-row: 6;
    grid-column: 3;
    justify-self: start;
  }

  .upcoming {
    top: 16px;
    right: 16px;
  }

  .unavailable {
    top: 16px;
    right: 16px;
    font-size: 14px;
    padding: 12px 18px;
  }

  .VillaTagContainer {
    top: 16px;
    left: 16px;
  }

  .wellness {
    bottom: 32px;
    left: 180px;
  }
}