.container {
  display: grid;
  grid-template: auto/1fr;
  gap: 16px;
  z-index: 0;
  padding: 20px 16px;
  border: 1px solid #0000001A;
  box-shadow: 0px 2px 4px 0px #0000000F;
  border-radius: 8px
}

.bookContainer {
  z-index: -1;
}

.book {
  border-radius: 4px;
  height: 48px;
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0px;
  text-transform: capitalize;
}

.startsFrom{
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0%;
  color: var(--secondary-contrast-text-2);
  text-transform: capitalize;
}

.book:disabled {
  opacity: 0.5;
  pointer-events: none;
}

.book :global .MuiButton-label {
  letter-spacing: 0.62px;
  font-size: 16px;
  font-weight: 500;
}

.preBookMeals{
 font-weight: 500;
 font-size: 13px;
 Line-height: 24px;
 color: rgba(130, 67, 32, 1);
 padding: 8px;
 height: 48px;
}

.strike {
  text-decoration: line-through;
}

.pricingContainer{
  display: grid;
  gap: 2px;
}

.discountedContainer{
  display: flex;
  gap: 4px;
  flex-direction: row;
  align-items: center;
}

.actualPricingContainer{
  display: flex;
  gap: 4px;
  flex-direction: row;
  align-items: center;
}

.strike {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0%;
  color: var(--secondary-contrast-text-2);
}

.rate {
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0%;
  color: var(--secondary-contrast-text-3); 
}

.subscript{
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: var(--secondary-contrast-text-2);
  place-self: end;
  text-transform: capitalize;
  margin-bottom: 4px;
}

.excTax {
  font-size: 10px;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  color: #a2a2a2;
}

.discount[data-single-child='false'] {
  grid-template: auto/auto 1fr;
  gap: 6px 8px;
  margin: -20px -16px 0 -16px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.discount :global .child {
  grid-column: span 2;
}

.llpTicker {
  padding: 8px;
}

.llpTicker:global.standalone {
  padding: 8px;
  margin: 0px -14px -18px -14px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.llpShortTicker {
  margin-right: 6px;
}

.stay{
  min-height: auto;
  padding: 8px 12px;
}

.occupancy{
  padding: 8px 12px;
  margin: 0;
  border: none;
  border-top:  1px solid #D7D7D7;
  border-radius: 0;
}

.package :global .input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top: none;
  border-left: none;
  border-bottom: none;
}

.package{
  align-content: center;
  border: 1px solid #D7D7D7;
  border-radius: 8px;
  padding: 8px 16px;
}

.package :global .expand-icon {
  top: 50%;
}

.package :global .input {
  background: none;
  width: -webkit-fill-available;
  font-size: 14px;
  padding: 0;
  border: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0px;
}

.package :global .label{
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0%;
  color: var(--secondary-contrast-text-2);
  opacity: 0.8;
}

.total{
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0%;
  color: var(--secondary-contrast-text);
  margin-top: 7px;
  text-transform: capitalize;
  flex-direction: row;
  display: flex;
  gap: 2px;
  align-items: center;
}



.dateGuestContainer{
  display: grid;
  border: 1px solid #D7D7D7;
  border-radius: 8px;
}

@media (max-width: 768px){
.preBookMeals{
  display: none;
}
}
