.container {
  color: var(--secondary-contrast-text);
  display: grid;
}

.headerContainer {
  margin-top: 16px;
  margin-bottom: 24px;
  display: grid;
  grid-template: 1fr/ 1fr auto;
}

.basicInfoLayout {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.titleContainer {
  display: grid;
  grid-template: auto auto / 1fr auto;
}

.title {
  margin: 0;
  font-weight: 700;
  line-height: 29.05px;
  display: flex;
  align-items: center;
  color: var(--secondary-contrast-text-3);
  text-overflow: ellipsis;
  overflow: hidden;
}

.propertyRatings {
  grid-row: 1;
  grid-column: 2;
}

/* .exclusiveCouponOffersContainer{
  box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.07);
  border-radius: 4px;
} */

.upcoming {
  border-radius: 4px;
  background-color: rgba(250, 100, 0, 0.1);
  font-weight: 500;
  letter-spacing: 1.2px;
  color: #cc580b;
  text-transform: uppercase;
  font-size: 10px;
  padding: 8px 14px;
  margin-left: 16px;
}

.subtitle {
  font-weight: 500;
  color: var(--secondary-contrast-text-2);
}

.subtitle .mapLink {
  text-decoration: underline;
  color: #1F2C38;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  padding: 0 8px;
  cursor: pointer;
}

.highlightsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 24px 30px;
  margin-top: 8px;
}

.highlights,
.eventContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  white-space: pre;
}

.eventContainer {
  display: grid;
}

.highlightsTitle,
.eventContainer {
  color: var(--secondary-contrast-text);
  font-weight: 500;
  font-size: 16px;
  line-height: 19.36px;
  letter-spacing: 0px;
}

.highlightsIcon {
  width: 20px;
  height: 20px;
}

.description {
  line-height: 1.7;
  white-space: pre-wrap;
}

.featured {
  font-size: 15px;
}

.featuredTitle {
  font-weight: 500;
  margin-top: 4px;
}

.similarPropertiesTitle,
.bedroomDetailsTitle {
  font-weight: 700; 
  color: var(--secondary-contrast-text-3);
}

.similarCarousel {
  margin-top: 8px;
}

.stickyContainer {
  display: grid;
  grid-template: auto/1fr;
  gap: 16px;
}

.needHelp {
  border: 1px solid var(--primary-main);
  color: var(--primary-main);
  height: 48px;
  border-radius: 4px;
  background-color: transparent;
  letter-spacing: 0.67px;
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 500;
  cursor: pointer;
  outline: none;
}

.callUs {
  text-align: center;
  color: var(--secondary-contrast-text-3);
  font-size: 14px;
  letter-spacing: 0.7px;
}

.callUs a {
  font-weight: 700;
}

.calculatorMobile {
  position: sticky;
  bottom: 0;
  margin: 0 -16px;
  overflow: unset;
  border-top: 1px dashed #0000001A;
}

.exclusiveCouponOffersMobile{
  position: sticky;
  bottom: 56px;
  margin: 0 -16px;
  border-top: 1px solid #0000001A;
}

.sendRequest {
  box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.07);
  border-radius: 4px;
}

.sendRequest :global .need-help {
  font-weight: 700;
  background: transparent;
  color: var(--secondary-contrast-text);
  font-size: 14px;
  min-height: unset;
  justify-content: flex-start;
}

.enquireSecondary {
  border: 1px solid var(--primary-main);
  color: var(--primary-main);
  height: 48px;
  border-radius: 4px;
  background: white;
  letter-spacing: 0.67px;
  text-transform: uppercase;
  font-weight: 500;
  cursor: pointer;
  outline: none;
  font-size: 14px;
}

.callUsSecondary {
  background: var(--primary-main);
  color: var(--primary-contrast-text);
  border-radius: 4px;
  text-transform: uppercase;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.mhTitle {
  color: var(--secondary-contrast-text-3);
  font-weight: 600;
}

.mhDescription {
  font-weight: 400;
  margin-bottom: 0px;
}

.divider,
.mobileDivider {
  border: 0.5px solid #91a3a74d;
}

.hideDivider {
  display: none;
}

@media (max-width: 768px) {

  .headerContainer {
    display: none;
  }

  .basicInfoLayout {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: -8px;
  }

  .calculatorDesktop{
    display: none;
  }

  .cancellationPolicy {
    margin-top: -24px;
    margin-bottom: -24px;
  }

  .container {
    grid-template: auto/1fr;
    gap: 24px;
    padding-bottom: 32px;
  }


  .title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 4px;
  }

  .subtitle {
    font-size: 12px;
    font-weight: 400;
  }

  .titleContainer {
    grid-template: none;
  }

  .propertyRatings {
    grid-row: 1;
    grid-column: 1;
  }


  .highlightsContainer {
    flex-direction: column;
    margin-top: 0px;
  }

  .highlightsTitle,
  .eventContainer {
    font-size: 14px;
  }

  .subtitle .mapLink {
    padding: 0 8px;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;    
  }

  .similarPropertiesTitle,
  .bedroomDetailsTitle {
    font-size: 16px;
    line-height: 19.36px;
  }

  .layoutStars {
    display: none !important;
  }

  /* .calculatorContainer {
    grid-row: 12;
  } */

  .stickyContainer {
    padding: 0 0 24px 0;
  }

  .couponCode {
    display: none;
  }

  .mhTitle {
    font-size: 16px;
    line-height: 19px;
    margin-top: 0px;
  }

  .mhDescription {
    font-size: 14px;
    line-height: 24px;
  }

  .mobileDivider {
    margin: 0px;
  }

  .mobileDivider[data-active="false"] {
    display: none;
  }

  .preBookMeals {
    background-color: rgba(250, 238, 231, 1);
    height: 48px;
    font-weight: 500;
    font-size: 12px;
    Line-height: 16px;
    color: rgba(130, 67, 32, 1);
    padding: 8px 16px;
    border-radius: 6px;
  }

  .hideDivider {
    display: flex;
  }

}

@media (min-width: 768px) {
  .container {
    grid-template: auto/minmax(0, 1fr) 364px;
    gap: 32px 40px;
    padding-bottom: 64px;
  }

  /* .reviews {
    margin-top: 32px;
  } */

  .banner {
    /* height: 47.22vh; */
    grid-column: span 2;
  }

  .title {
    font-size: 24px;
  }

  .subtitle {
    font-size: 16px;
    line-height: 28px;
    grid-column: 1;
  }

  .description {
      margin-top: -8px;
      margin-bottom: -8px;
  }

  .basicInfoLayout{
    margin-bottom: -8px;
  }

  .featuredTitle {
    margin-top: 4px;
    font-size: 15px;
  }

  .eventContainer {
    margin: -10px 0;
  }

  .similarProperties {
    grid-column: span 2;
  }

  .similarPropertiesTitle,
  .bedroomDetailsTitle {
    font-size: 24px;
    line-height: 29.05px;
  }

  .calculatorContainer {
    grid-row: 2/span 16;
    grid-column: 2;
  }

  .stickyContainer {
    position: sticky;
    top: 100px;
  }

  .calculatorMobile {
    display: none;
  }

  .exclusiveCouponOffersMobile{
    display: none;
  }

  .sendRequest {
    padding: 20px 16px;
  }

  .mhTitle {
    font-size: 32px;
    line-height: 35px;
    margin: 0px 0px;
  }

  .mhDescription {
    font-size: 16px;
    line-height: 26px;
  }

  .divider {
    margin: 0;
  }

  .preBookMeals {
    display: none;
  }
}