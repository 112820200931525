.container {
  display: grid;
}

.propertyTitle {
  display: flex;
  align-items: center;
  color: var(--secondary-contrast-text-2);
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  padding: 32px 0 0;
}

.propertyTitle::after {
  content: '';
  flex: 1;
  margin: 4px 0 0 12px;
  height: 3px;
  background: #ECECEC;
}

.anchor {
  width: 0;
  height: 0;
}

@media (max-width: 768px) {
  .container {
    grid-template: auto/repeat(auto-fit, minmax(110px, 1fr));
    gap: 8px;
  }

  .propertyTitle{
    font-size: 16px;
  }
}

@media (min-width: 768px) {
  .container {
    grid-template: auto/repeat(auto-fit, minmax(220px, 1fr));
    gap: 16px;
  }
}
